import Entity from './entity';
import EntityFactory from '@/game/entities/entity-factory';

class Gate extends Entity {
	constructor(scene, x, y, key) {
		super(scene, x, y, key);

		this.setInteractive();
		scene.gates.add(this);
		this.init();

		this.destinationName = null;
	}

	init() {
		super.init();

		let animationFrames = this.scene.anims.generateFrameNumbers('portals/blue');
		this.scene.anims.create({
			key: 'portals/blue',
			frames: animationFrames,
			frameRate: 3,
			repeat: -1,
			showOnStart: true
		});
		this.anims.play('portals/blue', true, 0);
	}

	load(config, baseConfig) {
		super.load(config, baseConfig);
		
		this.destinationName = config.destination;
	}
	save() {
		let config = super.save();

		config.destination = this.destinationName;

		return config;
	}
	
	getDestinationScene() {
		let sceneManager = this.scene.scene.manager;

		return sceneManager.scenes.find((scene) => {
			return scene.config && scene.systemConfig.name === this.destinationName;
		});
	}
	transportToDestination(entity) {
		let destinationScene = this.getDestinationScene();
		if(!destinationScene) {
			throw `Destination scene ${this.destinationName} not found`;
		}

		let entityConfig = entity.save();
		EntityFactory.getEntity(entityConfig, destinationScene);
		entity.destroy();
	}
}

export default Gate;