import Entity from './entity';
import EngineComponent from './components/engine';
import InternalSpaceComponent from './components/internal-space';
import CargoBayModule from './modules/cargo-bay';
import Trader from '../ai/trader';
import LifeSupportModule from './modules/life-support';
import GeneratorModule from './modules/generator';
import LivingQuartersModule from './modules/living-quarters';

class Ship extends Entity {
    constructor(scene, x, y, key) {
        super(scene, x, y, key);
        this.inherit(InternalSpaceComponent);
        this.initInternalSpace();
        this.inherit(EngineComponent);

        this.addInternalModule(new CargoBayModule(this));
        this.addInternalModule(new GeneratorModule(this));
        this.addInternalModule(new LifeSupportModule(this));
        this.addInternalModule(new LivingQuartersModule(this));

        this.setInteractive();
        this.on('pointerup', () => {
            this.scene.game.hud.shipInfo = this;
        });

        scene.ships.add(this);
        this.init();
    }

    load(config, baseConfig) {
        super.load(config, baseConfig);

        if(config.ai === 'Trader') {
            this.ai = new Trader(this);
        }
    }
}

export default Ship;