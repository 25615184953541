import Ship from "./ship";
import Station from "./station";
import Gate from './gate';
const merge = require('merge');

class EntityFactory {
    static classes = {
        ship: Ship,
        station: Station,
        gate: Gate
    };

    static configs = {};
    static addConfig(config) {
        this.configs[config.name] = config;
    }

    static getEntity(config, scene) {
        var baseConfig = this.configs[config.name];
        config = merge({}, baseConfig, config);

        var entity = new this.classes[config.type](scene, config.x, config.y, config.sprite);
        if(entity.load) {
            entity.load(config, baseConfig);
        }

        return entity;
    }
}

export default EntityFactory;