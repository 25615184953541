import Phaser from 'phaser';
import BootScene from './scenes/BootScene';
import TitleScene from './scenes/TitleScene';
import PlayScene from './scenes/PlayScene';
import GameOverScene from './scenes/GameOverScene';

// TODO: Make load config async
import universeConfig from '@/game/data/universe.js';

function launch() {
  var container = document.getElementById('game-container');

  return new Phaser.Game({
    type: Phaser.AUTO,
    width: container.scrollWidth,
    height: container.scrollHeight,
    parent: 'game-container',
    physics: {
      default: 'arcade'
    },
    scene: [BootScene, TitleScene, new PlayScene(universeConfig), GameOverScene]
  });
}

export default launch
export { launch }
