import GameItem from './game-item';
import AirFilter from './equipment/air-filter';

class ItemFactory {
    static classes = {
        'Air Filter': AirFilter
    };

    static getItem(config) {
        let classType = this.classes[config.name] || GameItem;
        return new classType(config);
    }
}

export default ItemFactory;