class Person {
    constructor(config) {
        this.load(config);
    }

    load(config) {
        this.name = config.name;
        this.title = config.title;
	}
	save() {
		return {
            name: this.name,
            title: this.title
		};
	}
}

export default Person;