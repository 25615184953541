import Entity from './entity';
import InternalSpaceComponent from './components/internal-space';
import CargoBayModule from './modules/cargo-bay';
import DockModule from './modules/dock';
import FactoryModule from './modules/factory';
import LifeSupportModule from './modules/life-support';
import GeneratorModule from './modules/generator';
import LivingQuartersModule from './modules/living-quarters';

class Station extends Entity {
    constructor(scene, x, y, key) {
        super(scene, x, y, key);
        this.inherit(InternalSpaceComponent);
        this.initInternalSpace();

        this.addInternalModule(new CargoBayModule(this));
        this.addInternalModule(new DockModule(this));
        this.addInternalModule(new FactoryModule(this));
        this.addInternalModule(new GeneratorModule(this));
        this.addInternalModule(new LifeSupportModule(this));
        this.addInternalModule(new LivingQuartersModule(this));

        this.setInteractive();
        this.on('pointerup', () => {
            this.scene.game.hud.stationInfo = this;
        });

        scene.stations.add(this);
        this.init();
    }
}

export default Station;