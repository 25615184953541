import InternalModule from "./internal-module";
import AirFilter from "../../items/equipment/air-filter";

const MIN_AIR_FILTER_TIME = 60;
class LifeSupportModule extends InternalModule {
    constructor(entity) {
        super(entity, 'lifeSupport');

        this.oxygen = 10;
        this.maxOxygen = 10;
        this.heat = 0;
        this.maxHeat = 100;
        this.heatDissipation = 1;
        this.airFilter = null;
    }

    load(config) {
		super.load(config);

        this.airFilter = null;
        if(config.airFilter) {
            this.airFilter = new AirFilter(config.airFilter);
        }
	}
    save() {
        return {
            oxygen: this.oxygen,
            maxOxygen: this.maxOxygen,
            heat: this.heat,
            maxHeat: this.maxHeat,
            heatDissipation: this.heatDissipation,
            airFilter: this.airFilter
        };
    }

    update(secondsPassed) {
        this.breath(secondsPassed);
        this.generateHeat(secondsPassed);
    }
    breath(secondsPassed) {
        let airRequired = this.airRequired();
        let airNeeded = secondsPassed * airRequired;
        this.oxygen = Math.max(0, this.oxygen - airNeeded);

        if(this.airFilter) {
            let newOxygen = Math.min(this.maxOxygen, this.oxygen + airNeeded * 2);
            let diff = newOxygen - this.oxygen;
            if(diff > 0) {
                this.oxygen = newOxygen;
                this.airFilter.currentLife -= diff;
            }
        }

        this.checkAirFilter();

        if(this.oxygen <= 0) {
            this.entity.livingQuarters.killCrew();
        }
    }
    airRequired() {
        return this.entity.livingQuarters.getTotalPeople();
    }
    checkAirFilter() {
        if(this.airFilter && this.airFilter.currentLife < 0) {
            this.airFilter = null;
        }

        if(!this.airFilter) {
            let airFilterDummy = new AirFilter({
                name: 'Air Filter',
                quantity: 1
            });

            this.airFilter = this.entity.cargoBay.removeItem(airFilterDummy);
        }
    }
    getAirFilterTimeLeft() {
        let airRequired = this.airRequired();
        let airFilterLife = this.airFilter?.currentLife || 0;

        let airFilters = this.entity.cargoBay.getItemByName('Air Filter');
        if(airFilters) {
            airFilterLife += airFilters.currentLife * airFilters.quantity;
        }

        return Math.ceil(airFilterLife / airRequired);
    }
    generateHeat(secondsPassed) {
        // TODO: Pull heat from running components onboard
        let newHeat = secondsPassed;
        this.heat += newHeat - this.heatDissipation * secondsPassed;
    }
    
    usesItem(item) {
        if(item.name === 'Air Filter') {
            return this.getAirFilterTimeLeft() < MIN_AIR_FILTER_TIME;
        }

        return false;
    }
    getResourcesNeeded() {
		if(this.getAirFilterTimeLeft() < MIN_AIR_FILTER_TIME) {
			return [{
                priority: 1000,
                item: new AirFilter({
                    name: 'Air Filter',
                    quantity: 1
                })
            }];
        }
        
        return [];
    }
}

export default LifeSupportModule;