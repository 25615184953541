import ItemFactory from "./item-factory";

class GameItem {
	constructor(config) {
		this.name = null;
		this.quantity = 1;

		if(config) {
			this.load(config);
		}
	}

	load(config) {
		this.name = config.name;
		this.quantity = config.quantity || 1;
	}
	save() {
		return {
			name: this.name,
			quantity: this.quantity
		};
	}

	clone() {
		return ItemFactory.getItem(this.save());
	}
}

export default GameItem;