import SystemScene from './system-scene';

export default class BackgroundSystemScene extends SystemScene {
  constructor (config) {
    super({ key: config.name });
    this.config = config;
    this.newGameConfig = config;
  }

  create () {
    super.create();
    this.loadSystemConfig(this.config);
  }
}
