import SupplyStationResource from './commands/supply-station-resource';
import AI from './ai';
import FetchStationResource from './commands/fetch-station-resource';

class Trader extends AI {
	constructor(entity) {
		super(entity, 'Trader');
	}

	getNextDecision() {
		let resourcesNeeded = this.entity.getResourcesNeeded();
		if(resourcesNeeded.length) {
			let stationWithResource = this.findNearestStationWithResources(resourcesNeeded[0]);
			if(stationWithResource) {
				return new FetchStationResource(this.entity, resourcesNeeded[0], stationWithResource);
			}
		}

		let stationsInNeed = this.findStationsNeedingResources();
		for(let i = 0; i < stationsInNeed.length; i++) {
			let {stationInNeed, resourceNeeded} = stationsInNeed[i];

			let stationWithResource = this.findNearestStationWithResources(resourceNeeded);
			if(stationWithResource) {
				return new SupplyStationResource(this.entity, stationInNeed, resourceNeeded, stationWithResource);
			}
		}

		return null;
	}

	findStationsNeedingResources() {
		let stations = this.entity.scene.stations.children.entries;
		let stationsInNeed = [];
		for(let i = 0; i < stations.length; i++) {
			let stationInNeed = stations[i];

			stationInNeed.getResourcesNeeded().forEach(({item, priority}) => {
				stationsInNeed.push({
					stationInNeed: stationInNeed,
					resourceNeeded: item,
					priority: priority
				});
			});
		}

		stationsInNeed.sort((a, b) => {
			if(a.priority === b.priority) {
				return 0;
			} else {
				return b.priority - a.priority;
			}
		});
		
		return stationsInNeed;
	}
	findNearestStationWithResources(resourceNeeded) {
		let stations = this.entity.scene.stations.children.entries;
		for(let i = 0; i < stations.length; i++) {
			let station = stations[i];

			// TODO: Check distance
			if(station.factory.hasProductForSale(resourceNeeded)) {
				return station;
			}
		}

		return null;
	}
}

export default Trader;