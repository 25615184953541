let StateMachine = require('javascript-state-machine');

class SupplyStationResource {
    constructor(ship, stationInNeed, resourceNeeded, stationWithResource) {
        this.ship = ship;
        this.stationInNeed = stationInNeed;
        this.resourceNeeded = resourceNeeded;
        this.stationWithResource = stationWithResource;

        var initState = 'idle';
        if(ship.dockedIn) {
            initState = 'docked';
        }

        const time = this.time = this.ship.scene.time;
        this.fsm = new StateMachine({
			init: initState,
			transitions: [
				{
					name: 'moveTowards',
					from: 'idle',
					to: 'movingTowards'
                },
                {
                    name: 'dock',
                    from: 'movingTowards',
                    to: 'docked'
                },
                {
                    name: 'undock',
                    from: 'docked',
                    to: 'idle'
                },
                {
                    name: 'buyResources',
                    from: 'docked',
                    to: 'docked'
                },
                {
                    name: 'sellResources',
                    from: 'docked',
                    to: 'done'
                }
			],
			methods: {
				onMoveTowards(event, destination) {
                    this.destination = destination;
                    ship.scene.physics.moveToObject(ship, destination, ship.speed * 12.5);
                },
                onDock() {
                    this.destination.dock.dockShip(ship);
                },
                onUndock() {
                    ship.dockedIn.undockShip(ship);
                },
                onBuyResources() {
                    // TODO: This will only work for exact same number of items!
                    let item = resourceNeeded.clone();
                    this.destination.cargoBay.removeItem(item);
                    ship.cargoBay.addItem(item);
                },
                onSellResources() {
                    let item = resourceNeeded.clone();
                    ship.cargoBay.removeItem(item);
                    this.destination.cargoBay.addItem(item);
                }
			}
		});

        this.eventLoop = time.addEvent({
            delay: 400,
            callback: () => {
                this.checkState();
            },
            loop: true
        });
        this.checkState();
    }

    checkState() {
        if(this.fsm.is('idle')) {
            let destination = null;
            if(this.ship.cargoBay.hasItemForSale(this.resourceNeeded)) {
                destination = this.stationInNeed;
            } else {
                destination = this.stationWithResource;
            }

            this.fsm.moveTowards(destination);
            if(this.ship.body.world.overlap(this.ship, destination)) {
                this.fsm.dock();
            }
        } else if(this.fsm.is('movingTowards')) {
            if(this.ship.body.world.overlap(this.ship, this.fsm.destination)) {
                this.fsm.dock();
            }
        } else if(this.fsm.is('docked')) {
            if(this.fsm.destination === this.stationWithResource) {
                this.fsm.buyResources();
                this.fsm.undock();
            } else if(this.fsm.destination === this.stationInNeed) {
                this.fsm.sellResources();
            } else {
                this.fsm.undock();
            }
        } else if(this.fsm.is('done')) {
            this.complete();
        }
    }

    complete() {
        this.destroy();
        this.ship.ai.commandComplete();
    }

    destroy() {
        this.eventLoop.remove();
    }
}

export default SupplyStationResource;