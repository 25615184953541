import InternalModule from './internal-module'

class DockModule extends InternalModule {
	constructor(entity) {
		super(entity, 'dock');

		this.maxDockSpace = 0;
		this.dockedShips = [];
	}

	hasShips() {
		return this.dockedShips.length > 0;
	}
	inDock(ship) {
		return this.dockedShips.includes(ship);	
	}
	dockShip(ship) {
		ship.body.setVelocity(0, 0);
		ship.visible = false;
		ship.dockedIn = this;

		this.dockedShips.push(ship);
	}
	undockShip(ship) {
		this.dockedShips.splice(this.dockedShips.indexOf(ship), 1);

		ship.visible = true;
		ship.dockedIn = null;
	}
}

export default DockModule;