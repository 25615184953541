let InternalSpaceComponent = {
	maxInternalSpace: 100,
	internalModules: [],
	updateModules: [],

	initInternalSpace() {
		this.internalSpaceTimer = this.scene.time.addEvent({
            delay: 100,
            callback: () => {
                this.updateInternalModules();
            },
            loop: true
        });
	},

	addInternalModule(internalModule) {
		internalModule.entity = this;
		this.internalModules.push(internalModule);

		if(internalModule.update) {
			this.updateModules.push(internalModule);
		}
	},

	updateInternalModules() {
		var secondsPassed = this.internalSpaceTimer.elapsed / 1000;
		this.updateModules.forEach((module) => {
			module.update(secondsPassed);
		});
	},

	usesItem(item) {
		for(let i = 0; i < this.internalModules.length; i++) {
			let module = this.internalModules[i];

			if(module.usesItem && module.usesItem(item)) {
				return true;
			}
		}

		return false;
	},
	getHighestPriorityResourceNeeded() {
		let resources = this.getResourcesNeeded();
        return resources.length ? resources[0] : null;
	},
	getResourcesNeeded() {
		let resources = [];
		
		this.internalModules.forEach((module) => {
			if(module.getResourcesNeeded) {
				resources.push(...module.getResourcesNeeded());
			}
		});

		resources.sort((a, b) => {
			if(a.priority === b.priority) {
				return 0;
			} else {
				return b.priority - a.priority;
			}
		});

        return resources;
    },

	destroyInternalSpace() {
		this.internalSpaceTimer.remove();

		this.internalModules.forEach(module => {
            if(module.destroy) {
                module.destroy();
            }
        });
	}
};

export default InternalSpaceComponent;