const MAKE_DESCISION_INTERVAL = 2000;

class AI {
	constructor(entity, name) {
		this.entity = entity;
		this.name = name;

		this.entity.scene.time.addEvent({
            delay: 50,
            callback: () => {
                this.makeDecision();
            }
		});
	}

	commandComplete() {
		this.command = null;
		this.makeDecision();
	}

	makeDecision() {
		if(this.command) {
			return;
		}
		if(this.entity.livingQuarters.isCrewDead()) {
			this.destroy();
			return;
		}

		if(this.getNextDecision) {
			this.command = this.getNextDecision();
		}

		if(!this.command) {
			this.entity.scene.time.addEvent({
				delay: MAKE_DESCISION_INTERVAL,
				callback: () => {
					this.makeDecision();
				}
			});
		}
	}

	destroy() {
		this.entity.ai = null;
		if(this.command && this.command.destroy) {
			this.command.destroy();
		}
	}
}

export default AI;