import { Scene } from 'phaser';

export default class TitleScene extends Scene {
  constructor () {
    super({ key: 'TitleScene' })
  }

  create () {
    this.background = this.add.tileSprite(this.scale.canvasBounds.width / 2, this.scale.canvasBounds.height / 2, this.scale.canvasBounds.width, this.scale.canvasBounds.height, 'backgrounds/stars');
    this.background.setDisplaySize(this.scale.canvasBounds.width, this.scale.canvasBounds.height);
    this.background.setScrollFactor(0);
    this.background.parallax = 24;

    this.game.hud.titleScreen = true;
  }

  update (totalTime, elapsedTime) {
    this.background.tilePositionY += elapsedTime / this.background.parallax;
  }
}
