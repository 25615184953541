import InternalModule from "./internal-module";
import Person from "../../people/person";

class LivingQuartersModule extends InternalModule {
	constructor(entity) {
		super(entity, 'livingQuarters');

		this.crew = [];
		this.passengers = [];
	}

	load(config) {
		super.load(config);

		this.crew = [];
		this.passengers = [];
		config.crew.forEach((crewMember) => {
			this.crew.push(new Person(crewMember));
		});

		config.passengers.forEach((passenger) => {
			this.passengers.push(new Person(passenger));
		});
	}
	save() {
		var config = {
			crew: this.crew.map((crew) => {
				return crew.save();
			}),
			passengers: this.passengers.map((passenger) => {
				return passenger.save();
			})
		};

		return config;
	}

	getTotalPeople() {
		return this.crew.length + this.passengers.length;
	}
	isCrewDead() {
		return this.getTotalPeople() === 0;
	}

	killCrew() {
		this.crew = [];
		this.passengers = [];
	}
}

export default LivingQuartersModule;