import InternalModule from "./internal-module";

class GeneratorModule extends InternalModule {
	constructor(entity) {
		super(entity, 'generator');

		this.power = 10;
		this.maxPower = 10;
	}

	save() {
        return {
			power: this.power,
			maxPower: this.maxPower
        };
	}
	
	update(secondsPassed) {
		this.generatePower(secondsPassed);
	}

	// TODO: Replace with an actual equipment driving this
	generatePower(secondsPassed) {
		this.power = Math.min(this.maxPower, this.power + secondsPassed * 1);
	}
}

export default GeneratorModule;