import GameItem from "../game-item";

class AirFilter extends GameItem {
	load(config) {
		super.load(config);

		this.currentLife = config.currentLife || 100;
		this.maxLife = config.maxLife || 100;
	}

	save() {
		let config = super.save();

		config.currentLife = this.currentLife;
		config.maxLife = this.maxLife;

		return config;
	}
}

export default AirFilter;