import { Input } from 'phaser';
import EntityFactory from '@/game/entities/entity-factory';
import SystemScene from './system-scene';
import BackgroundSystemScene from './background-system-scene';

export default class PlayScene extends SystemScene {
	constructor (config) {
		super({ key: config.name });
		this.config = config;
		this.newGameConfig = config;
	}

	create() {
		super.create();

		this.background = this.add.tileSprite(this.scale.canvasBounds.width / 2, this.scale.canvasBounds.height / 2, this.scale.canvasBounds.width, this.scale.canvasBounds.height, 'backgrounds/stars');
		this.background.setDisplaySize(this.scale.canvasBounds.width, this.scale.canvasBounds.height);
		this.background.setScrollFactor(0);
		this.background.parallax = 4;

		this.playerShip = null;
		this.loadConfig(this.config);

		if(!this.playerShip) {
			this.playerShip = EntityFactory.getEntity({
				name: 'Cobra',
				x: this.game.config.width * 0.2,
				y: this.game.config.height * 0.32
			}, this);
			let airFilter = this.playerShip.lifeSupport.airFilter;
			airFilter.currentLife = airFilter.maxLife = 10000000;
			this.initPlayerShip();
		}

		this.game.hud.time = this.time;
		this.keys = this.initKeyBindings();
		this.game.scale.on('resize', this.onResize, this);
	}

	onResize(event) {
		this.cameras.resize(event._width, event._height);

		this.background.setSize(this.scale.canvasBounds.width, this.scale.canvasBounds.height);
		this.background.setDisplaySize(this.scale.canvasBounds.width, this.scale.canvasBounds.height);
		this.background.setPosition(this.scale.canvasBounds.width / 2, this.scale.canvasBounds.height / 2);
	}

	loadConfig(config) {
		let systemConfig = config.systems.find((system) => {
			return system.name === config.currentSystem;
		});

		this.loadSystemConfig(systemConfig);
		this.config.systems.forEach((systemConfig) => {
			if(systemConfig.name === config.currentSystem) {
				return;
			}

			let scene = this.getSystemSceneByName(systemConfig.name);
			if(scene) {
				scene.loadSystemConfig(systemConfig);
			} else {
				scene = new BackgroundSystemScene(systemConfig);
				this.scene.add(scene.key, scene, true);
				scene.scene.setVisible(false);
			}
		});

		if(systemConfig.playerShip) {
			this.setPlayerShip(EntityFactory.getEntity(systemConfig.playerShip, this));
		}
	}
	setPlayerShip(playerShip) {
		this.playerShip = playerShip;
		this.initPlayerShip();
	}
	initPlayerShip() {
		this.cameras.main.startFollow(this.playerShip);
		this.game.hud.playerShip = this.playerShip;
	}

	saveConfig() {
		let systemConfig = this.saveSystemConfig();
		systemConfig.playerShip = systemConfig.ships.find((shipConfig) => {
			return shipConfig.id === this.playerShip.id;
		});
		systemConfig.ships = systemConfig.ships.filter((shipConfig) => {
			return shipConfig.id !== this.playerShip.id;
		});

		let config = {
			name: this.config.name,
			currentSystem: this.systemConfig.name,
			systems: [
				systemConfig,
				...this.getOtherSystemScenes().map((scene) => {
					return scene.saveSystemConfig()
				})
			]
		};

		return config;
	}

	update (totalTime, elapsedTime) {
		this.handlePlayerInput(totalTime, elapsedTime);

		this.background.tilePositionX = this.cameras.main.midPoint.x * this.cameras.main._zoom / this.background.parallax;
		this.background.tilePositionY = this.cameras.main.midPoint.y * this.cameras.main._zoom / this.background.parallax;
	}
	canPlayerInput() {
		return !!this.playerShip.visible && !this.time.paused;
	}
	handlePlayerInput(totalTime, elapsedTime) {
		if(!this.canPlayerInput()) {
			return;
		} else if(this.playerShip.livingQuarters.isCrewDead()) {
			this.scene.start('GameOverScene');
		}

		if(this.keys.playerUp.isDown) {
			this.playerShip.fireThruster(elapsedTime);
		} else if(this.keys.playerDown.isDown) {
			this.playerShip.reverseThruster(elapsedTime);
		} else {
			this.playerShip.body.setVelocity(0, 0);
		}

		if(this.keys.playerLeft.isDown) {
			this.playerShip.turnLeft(elapsedTime);
		} else if(this.keys.playerRight.isDown) {
			this.playerShip.turnRight(elapsedTime);
		}
	}

	initKeyBindings() {
		var keys = {};

		keys.playerInteract = this.input.keyboard.addKey(Input.Keyboard.KeyCodes.E);
		keys.playerInteract.on('down', () => {
			if(!this.canPlayerInput()) {
				return;
			}

			this.physics.overlap(this.playerShip, this.stations, (ship, station) => {
				station.dock.dockShip(ship);
				this.game.hud.dockedInStation = station;
			});
			
			this.physics.overlap(this.playerShip, this.gates, (ship, gate) => {
				gate.transportToDestination(ship);
				
				let startingConfig = this.saveSystemConfig();
				let destinationScene = gate.getDestinationScene();
				let destinationConfig = destinationScene.saveSystemConfig();

				this.loadSystemConfig(destinationConfig);
				destinationScene.loadSystemConfig(startingConfig);
				destinationScene.key = startingConfig.name;
				this.setPlayerShip(this.getShipById(ship.id));
			});
		});
		keys.playerUp = this.input.keyboard.addKey(Input.Keyboard.KeyCodes.W);
		keys.playerDown = this.input.keyboard.addKey(Input.Keyboard.KeyCodes.S);
		keys.playerLeft = this.input.keyboard.addKey(Input.Keyboard.KeyCodes.A);
		keys.playerRight = this.input.keyboard.addKey(Input.Keyboard.KeyCodes.D);

		keys.togglePause = this.input.keyboard.addKey(Input.Keyboard.KeyCodes.ESC);
		keys.togglePause.on('down', () => {
			this.game.hud.escape();
		});

		keys.toggleUniverseInfo = this.input.keyboard.addKey(Input.Keyboard.KeyCodes.BACKTICK);
		keys.toggleUniverseInfo.on('down', () => {
			this.game.hud.universeInfoScreen = !this.game.hud.universeInfoScreen;
		});

		return keys;
	}
}
