import Phaser from 'phaser'
import isPlainObject from 'is-plain-object';
const deepEqual = require('deep-equal');
const deepCopy = require('deep-copy');
const generateUniqueId = require('uuid/v4');

export default class Entity extends Phaser.GameObjects.Sprite {
	constructor(scene, x, y, key) {
		super(scene, x, y, key);
		this.scene = scene;
		this.id = generateUniqueId();
	}

	init() {
		this.scene.add.existing(this);
		this.scene.physics.world.enableBody(this, 0);

		this.on('destroy', this.onDestroy, this);
	}
	inherit(component) {
		Object.assign(this, deepCopy(component));
	}

	load(config, baseConfig) {
		for(var name in config) {
			if(isPlainObject(config[name])) {
				if(this[name] && this[name].load) {
					this[name].load(config[name]);
				}
			} else {
				this[name] = config[name];
			}
		}

		this.config = baseConfig;
	}

	save() {
		var config = {
			id: this.id,
			name: this.config.name,
			type: this.config.type,
			sprite: this.config.sprite,
			scaleX: this.config.scaleX,
			scaleY: this.config.scaleY,
			x: this.x,
			y: this.y,
			angle: this.angle,
			ai: this.ai?.name
		};
		
		if(this.internalModules) {
			this.internalModules.forEach((module) => {
				if(module.save) {
					config[module.name] = module.save();
				}
			});
		}

		this.stripConfigDuplicates(config);
		return config;
	}
	stripConfigDuplicates(config) {
		for(var name in config) {
			if(['name'].includes(name)) {
				continue;
			}

			// TODO: Does not actually work at the moment since even having a different currentProductionTime will stop it
			if(isPlainObject(config[name]) && isPlainObject(this.config[name])) {
				if(deepEqual(config[name], this.config[name])) {
					delete config[name];
				}
			} else if(config[name] === this.config[name]) {
				delete config[name];
			}
		}
	}

	onDestroy() {
		if(this.internalModules) {
			this.internalModules.forEach(module => {
				if(module.destroy) {
					module.destroy();
				}
			});
		}

		if(this.ai && this.ai.destroy) {
			this.ai.destroy();
		}
	}
}