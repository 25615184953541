import InternalModule from './internal-module';
import GameItem from '@/game/items/game-item';

class FactoryModule extends InternalModule {
    constructor(entity) {
        super(entity, 'factory');

        this.currentProductionTime = 0;
        this.requiredProductionTime = 1;
    }

    load(config) {
        super.load(config);

        this.products = [];
        this.resources = [];
        this.currentProductionTime = config.currentProductionTime || 0;
        this.requiredProductionTime = config.requiredProductionTime || 1;

        (config.products || []).forEach((productConfig) => {
            this.products.push(new GameItem(productConfig));
        });

        (config.resources || []).forEach((resourceConfig) => {
            this.resources.push(new GameItem(resourceConfig));
        });
    }
    save() {
        return {
            currentProductionTime: this.currentProductionTime,
            requiredProductionTime: this.requiredProductionTime,
            products: this.products.map((item) => {
                return item.save();
            }),
            resources: this.resources.map((item) => {
                return item.save();
            })
        };
    }

    update(secondsPassed) {
        if(!this.canProduceProducts()) {
            return;
        }

        this.currentProductionTime += secondsPassed;
        if(this.currentProductionTime > this.requiredProductionTime) {
            this.produceProducts();
            this.currentProductionTime -= this.requiredProductionTime;
        }
    }

    canProduceProducts() {
        return this.hasEnoughOfResources() && !this.entity.livingQuarters.isCrewDead();
    }
    hasEnoughOfResources() {
        return this.resources.filter((resource) => {
            return !!this.hasEnoughOfResource(resource);
        }).length === this.resources.length
    }
    hasEnoughOfResource(resource) {
        return !!this.entity.cargoBay.getItem(resource);
    }
    produceProducts() {
        let cargoBay = this.entity.cargoBay;
        
        this.products.forEach((product) => {
            cargoBay.addItem(product.clone());
        });

        this.resources.forEach((resource) => {
            cargoBay.removeItem(resource);
        });
    }

    get progress() {
        return this.currentProductionTime / this.requiredProductionTime * 100;
    }

    hasProductForSale(item) {
        return this.products.filter((product) => {
            return product.name === item.name;
        }).length && this.entity.cargoBay.hasItemForSale(item);
    }

    usesItem(item) {
        return this.resources.filter(factoryResource => {
            return factoryResource.name === item.name;
        }).length;
    }
    getResourcesNeeded() {
        let resources = [];

        this.resources.forEach((resource) => {
            let priority = 10;

            let resourceInCargo = this.entity.cargoBay.getItemByName(resource.name);
            if(resourceInCargo) {
                priority -= resourceInCargo.quantity / resource.quantity;
            }

            resources.push({
                priority: priority,
                item: resource
            });
        });

        return resources;
    }
}

export default FactoryModule;