import isPlainObject from 'is-plain-object';

class InternalModule {
	constructor(entity, name) {
        this.entity = entity;
        this.name = name;
        this.entity[name] = this;
	}

	load(config) {
		for(var name in config) {
            if(isPlainObject(config[name]) || Array.isArray(config[name])) {
                // TODO: Implement
            } else {
                this[name] = config[name];
            }
        }
	}
}

export default InternalModule;