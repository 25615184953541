import { Scene } from 'phaser';
import EntityFactory from '@/game/entities/entity-factory';

export default class SystemScene extends Scene {
	create () {
		this.ships = this.physics.add.group();
		this.stations = this.physics.add.group();
		this.gates = this.physics.add.group();
		this.systemScene = true;
	}

	loadSystemConfig(config) {
		this.systemConfig = config;
		this.ships.clear(true, true);
		this.stations.clear(true, true);
		this.gates.clear(true, true);

		config.stations.forEach((stationConfig) => {
			EntityFactory.getEntity(stationConfig, this);
		});

		config.ships.forEach((shipConfig) => {
			EntityFactory.getEntity(shipConfig, this);
		});

		(config.gates ?? []).forEach((gateConfig) => {
			gateConfig.name = 'Gate';
			EntityFactory.getEntity(gateConfig, this);
		});
	}

	saveSystemConfig() {
		var config = {
			name: this.systemConfig.name,
			stations: this.stations.children.entries.map((station => {
				return station.save();
			})),
			ships: this.ships.children.entries.map((ship => {
				return ship.save();
			})),
			gates: this.gates.children.entries.map((gate => {
				return gate.save();
			}))
		};

		return config;
	}

	getEntities() {
		let entities = [];

		entities.push(...this.time.scene.ships.children.entries);
		entities.push(...this.time.scene.stations.children.entries);

		return entities;
	}
	getAllEntities() {
		return this.getSystemScenes().map((scene) => {
			return scene.getEntities();
		}).flat();
	}

	getShipById(id) {
		return this.time.scene.ships.children.entries.find((ship) => {
			return ship.id === id;
		});
	}
	getAllShips() {
		return this.getSystemScenes().map((scene) => {
			return scene.ships.children.entries;
		}).flat();
	}
	getAllStations() {
		return this.getSystemScenes().map((scene) => {
			return scene.stations.children.entries;
		}).flat();
	}

	getOtherSystemScenes() {
		return this.getSystemScenes().filter((scene) => {
			return scene !== this;
		});
	}
	getSystemScenes() {
		let sceneManager = this.scene.manager;

		return sceneManager.scenes.filter((scene) => {
			return scene.systemScene === true;
		});
	}
	getSystemSceneByName(name) {
		let sceneManager = this.scene.manager;

		return sceneManager.scenes.find((scene) => {
			return scene.systemConfig && scene.systemConfig.name === name;
		});
	}
}
