import InternalModule from './internal-module';
import ItemFactory from '../../items/item-factory';

class CargoBayModule extends InternalModule {
	constructor(entity) {
		super(entity, 'cargoBay');

		this.maxCargoSpace = 0;
		this.items = [];
	}

	hasItem(item) {
		return !!this.getItem(item);
	}
	hasItemForSale(item) {
		if(!this.hasItem(item)) {
			return false;
		}

		if(this.entity.usesItem(item)) {
			return false;
		}

		return true;
	}
	getItem(item) {
		return this.items.find((match) => {
			return match.name === item.name && match.quantity >= item.quantity;
		});
	}
	getItemByName(name) {
		return this.items.find((match) => {
			return match.name === name;
		});
	}
	addItem(item) {
		var match = this.items.find((match) => {
			return match.name === item.name;
		});

		if(match) {
			match.quantity += item.quantity;
		} else {
			this.items.push(item);
		}
	}
	removeItem(item) {
		var match = this.getItem(item);

		if(match) {
			match.quantity -= item.quantity;
			if(match.quantity <= 0) {
				this.items.splice(this.items.indexOf(match), 1);
			}

			return item;
		} else {
			return null;
		}
	}

	load(config) {
		super.load(config);

		this.items = [];
		config.items.forEach((item) => {
			this.addItem(ItemFactory.getItem(item));
		});
	}
	save() {
		var config = {
			maxCargoSpace: this.maxCargoSpace,
			items: this.items.map((item) => {
				return item.save();
			})
		};

		return config;
	}
}

export default CargoBayModule;