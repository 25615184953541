import { Scene } from 'phaser'
import EntityFactory from '@/game/entities/entity-factory';

import cobra from '@/game/data/ships/cobra.json';
import oreMine from '@/game/data/stations/ore-mine.json';
import oreRefinery from '@/game/data/stations/ore-refinery.json';
import airFilterFactory from '@/game/data/stations/air-filter-factory.json';

import stars from '@/game/assets/backgrounds/stars.jpg';
import ship from '@/game/assets/ships/cobra.png';
import station from '@/game/assets/stations/station.png';
import asteroid from '@/game/assets/planetoids/asteroid.png';
import bluePortal from '@/game/assets/portals/portal_blue_3.png';

export default class BootScene extends Scene {
	constructor () {
		super({ key: 'BootScene' });
	}

	preload () {
		this.game.hud.loading = 0;
		this.load.on('progress', (value) => {
			this.onProgress(value);
		});

		EntityFactory.addConfig(cobra);
		EntityFactory.addConfig(oreMine);
		EntityFactory.addConfig(oreRefinery);
		EntityFactory.addConfig(airFilterFactory);
		EntityFactory.addConfig({
			name: 'Gate',
			type: 'gate',
			sprite: 'portals/blue',
			scaleX: 1.5,
			scaleY: 1.5
		});

		this.load.image('backgrounds/stars', stars);
		this.load.image('cobra', ship);
		this.load.image('station', station);
		this.load.image('asteroid', asteroid);

		this.load.spritesheet('portals/blue', bluePortal, {
			frameWidth: 432,
			frameHeight: 192
		});
	}

	create () {
		this.game.hud.loading = null;
		this.game.hud.time = this.time;
		this.scene.start('TitleScene');
	}

	onProgress(value) {
		this.game.hud.loading = value * 100;
	}
}
