let EngineComponent = {
    speed: 20,
    turning: 0.1,

    fireThruster(elapsedTime) {
        var velocity = this.scene.physics.velocityFromAngle(this.angle - 90, this.speed * elapsedTime);
        this.body.setVelocity(velocity.x, velocity.y);
    },
    reverseThruster(elapsedTime) {
        var velocity = this.scene.physics.velocityFromAngle(this.angle + 90, this.speed * elapsedTime);
        this.body.setVelocity(velocity.x, velocity.y);
    },

    turnLeft(elapsedTime) {
        this.angle -= this.turning * elapsedTime;
    },
    turnRight(elapsedTime) {
        this.angle += this.turning * elapsedTime;
    }
};

export default EngineComponent;